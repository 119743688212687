import { createI18n } from 'vue-i18n' //引入vue-i18n组件
import { Locale } from 'vant'
import messages from './index'
const lang = localStorage.getItem('lang') || 'en-en'
import zhCN from 'vant/es/locale/lang/zh-CN';
import deDE from 'vant/lib/locale/lang/de-DE'
import jaJP from 'vant/lib/locale/lang/ja-JP'
import enUS from 'vant/lib/locale/lang/en-US'
import esES from 'vant/lib/locale/lang/es-ES'
// import arSA from 'vant/lib/locale/lang/ar-SA'
import frFR from 'vant/lib/locale/lang/fr-FR'
import koKR from 'vant/lib/locale/lang/ko-KR'
import ptBR from 'vant/lib/locale/lang/pt-BR'
import ruRU from 'vant/lib/locale/lang/ru-RU'

const i18n = createI18n({
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  fallbackLocale: 'en-en',
  globalInjection: true,
  legacy: false, // you must specify 'legacy: false' option
  locale: lang,
  messages,
});
// 更新vant组件库本身的语言变化，支持国际化
function vantLocales(language) {
  if (language === 'hy-hy') {
    Locale.use(language, zhCN)
  } else if (language === 'de-de') {
    Locale.use(language, deDE)
  } else if (language === 'jp-jp') {
    Locale.use(language, jaJP)
  } else if (language === 'en-en') {
    Locale.use(language, enUS)
  } else if (language === 'es-es') {
    Locale.use(language, esES)
  } else if (language === 'fr-fr') {
    Locale.use(language, frFR)
  } else if (language === 'ko-ko') {
    Locale.use(language, koKR)
  } else if (language === 'pt-pt') {
    Locale.use(language, ptBR)
  } else if (language === 'ru-ru') {
    Locale.use(language, ruRU)
  }
}

export { i18n, vantLocales }